html,
body,
#root {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 80px;

  animation: App-logo-spin infinite 20s linear;
}

.App-header {
  height: 150px;
  padding: 20px;

  color: white;
  background-color: #222222;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.ReactModal__Content {
  transition: all 500ms ease-in-out;
  transform: translateY(30px);

  opacity: 0;
}

.ReactModal__Content--after-open {
  transform: translateY(0);

  opacity: 1;
}

.ReactModal__Content--before-close {
  transform: translateY(30px);

  opacity: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-datepicker-wrapper {
  display: block !important;
}

.react-datepicker__input-container > input {
  font-family: 'Roboto', sans-serif !important;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  height: 34px;
  line-height: 1.42857143;
  border-radius: 3px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.react-datepicker__input-container > input:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.react-datepicker__time-list-item.react-datepicker__time-list-item--disabled {
  display: none;
}

#sell-action-mode {
  padding-left: 20px;
  padding-right: 20px;
}

#sell-action-mode > .tab-content {
  border: 1px solid #ccc;
  border-top: none;
}

.form-group:last-child {
  margin-bottom: 0 !important;
}

.rooms-table {
  margin: 0 16px 0 0;
}

.rooms-table:last-child {
  margin-right: 0;
}

.rooms-table th,
.rooms-table td {
  text-align: center;
  padding: 10px;
  border: 1px solid gainsboro;
}

.inventory-table {
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
}

.inventory-table th,
.inventory-table td {
  text-align: center;
  padding: 10px;
  border: 1px solid gainsboro;
}

.breakfast-actions-table {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.breakfast-actions-table th,
.breakfast-actions-table td {
  text-align: center;
  padding: 10px;
  border: 1px solid gainsboro;
}

.loading-spinner {
  position: relative;

  width: 40px;
  height: 40px;
  margin: 100px auto;
}

.cube1,
.cube2 {
  position: absolute;
  top: 0;
  left: 0;

  width: 15px;
  height: 15px;

  -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
  animation: sk-cubemove 1.8s infinite ease-in-out;

  background-color: #ffffff;
}

.cube2 {
  -webkit-animation-delay: -.9s;
  animation-delay: -.9s;
}

@-webkit-keyframes sk-cubemove {
  25% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(.5);
  }
  50% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
    scale(.5);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes sk-cubemove {
  25% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(.5);
    transform: translateX(42px) rotate(-90deg) scale(.5);
  }
  50% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
    transform: translateX(42px) translateY(42px) rotate(-179deg);
  }
  50.1% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
    transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
    scale(.5);
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(.5);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}