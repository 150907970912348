html {
  font-size: 13px;
}

body {
  font-family: Roboto, sans-serif !important;

  margin: 0;
  padding: 0;
}

body.no-scroll {
  overflow-y: hidden;
}

.btn {
  font-family: Roboto, sans-serif !important;

  letter-spacing: 0.3px;
}

.right-side h1,
.right-side h2,
.right-side h3,
.right-side h4,
.right-side h5,
.right-side h6 {
  font-family: Roboto, sans-serif !important;
}
